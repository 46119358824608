import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import { TextField, Button } from "@material-ui/core";
import styles from "./styles.module.scss";
import req from "../../utils/req";
import { setCookie } from "../../utils/cookie";

const Signin = () => {
  const [pass, setPass] = useState("");
  const history = useHistory();

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#46aba0",
      },
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const auth = async (password) => {
      // console.log({ pass: password });

      try {
        const { data } = await req.post("auth/authadmin", { pass: password });

        if (data.redirect) {
          // console.log("resTrue", data);
          setCookie("ad", data.token);
          toast.success("Authorization successful", {
            position: toast.POSITION.TOP_RIGHT,
          });
          history.push("/admin");
        }
      } catch (error) {
        // console.log("1", error);
        toast.error("Wrong password", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };

    auth(pass);
  };

  const handleChange = (e) => {
    setPass(e.target.value);
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit}>
        <ThemeProvider theme={theme}>
          <TextField
            className={styles.input}
            id="outlined-basic"
            label="password"
            variant="outlined"
            onChange={handleChange}
            style={{ background: "white" }}
          />
        </ThemeProvider>
        <button
          className={styles.button}
          type="submit"
          onClick={handleSubmit}
          style={{ marginTop: "10px" }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Signin;
