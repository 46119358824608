import axios from "axios";

// const protHost = `${window.location.href.split(":")[0]}:${
//   window.location.href.split(":")[1]
// }`;
// window.axios = axios;
const API = `//${window.location.hostname}:9000/`;

const req = {
  post(route, config) {


    return axios.post(`${API}${route}`, config);
  },
  get(route, config) {
    return axios.get(`${API}${route}`, config);
  },
};

export default req;


